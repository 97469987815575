import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("account.resetPassword.title"),
            isPasswordSet: false,
            errorMessage: "",
            newPassword: "",
            newPasswordConfirm: "",
            validationOptions: {
                rules: {
                    newPassword: {
                        required: true,
                        minLength: 8,
                        maxLength: 255
                    },
                    newPasswordConfirm: {
                        required: true,
                        minLength: 8,
                        maxLength: 255,
                        match: [
                            (args) => {
                                return (this.newPassword == this.newPasswordConfirm);
                            },
                            this.$t("account.changePassword.passwordConfirmationNotMatch")
                        ]
                    }
                }
            }
        };
    },
    methods: {
        async setPassword() {
            await this.$store.dispatch("account/resetPassword", {
                email: this.$route.query.email,
                token: this.$route.query.token,
                newPassword: this.newPassword,
            });
            this.isPasswordSet = true;
        }
    }
});
